import React from 'react';
import { FaRegClipboard } from 'react-icons/fa';

function Donation() {
    return (
        <div className="App">
            <main className="App-main">
                <div className="support-us">
                    <p>Support My Work</p>
                    <p>Your support is crucial in helping us continue to develop and improve our services. Every donation, no matter the size, goes directly into enhancing our tools and resources to better serve you and the entire community. Your generosity allows us to maintain and expand our offerings, ensuring that we can keep providing high-quality content and innovative solutions.</p>
                    <p>We deeply appreciate your contribution, as it enables us to stay independent and focused on delivering the best possible experience for our users. Thank you for helping us grow and sustain our mission!</p>
                    <div className="donation-line">
                        <span>
                            <p class="large-text"><strong>Bitcoin (BTC):</strong> bc1qvt699c55dhq9ut82nhjku36tqr2a9qlk05hkpm</p>
                            <FaRegClipboard
                                className="copy-icon"
                                onClick={() => navigator.clipboard.writeText('bc1qvt699c55dhq9ut82nhjku36tqr2a9qlk05hkpm')}
                                title="Copy to Clipboard"
                            />
                        </span>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Donation;