import React from 'react';

function GettingStarted() {
  return (
    <div className="App">
      <main className="App-main">
        <div className="getting-started-content">
          {/* Add your article content here */}
          {/* <h1><strong>User Manual</strong></h1> */}

<h2><strong>Introduction</strong></h2>

<p>This manual will guide you through the process of securely storing your 24-word recovery phrase within a 120-word sheet, providing you with a simple yet highly secure method to protect your cryptocurrency assets.</p>

<h3><strong>The power of P(120,24)</strong></h3>

<p>P(120,24) represents the number of possible ways to arrange 24 items selected from a pool of 120, where the order of selection matters. It’s a permutation calculation that results in an astronomically large number, specifically 6.75 × 10^48. This means there are over <strong>6.75 trillion trillion trillion trillion</strong> possible combinations, making it incredibly difficult—essentially impossible—for anyone or anything to guess the exact sequence. It’s a powerful concept used to create secure, nearly uncrackable systems, like hiding a 24-word recovery phrase within a 120-word list.</p>

<h2><strong>Step-by-Step Guide</strong></h2>

<h3><strong>Step 1: Generate Your 120-Word Sheet</strong></h3>

<ol>
    <li><strong>Visit Hidephrase.com:</strong> Open your web browser and go to <a href="https://hidephrase.com">Hidephrase.com</a>.</li>
    <li><strong>Generate the 120-Word Sheet:</strong> Click on the "Regenerate Words" button. The website will randomly select 120 words from the BIP39 wordlist. These words will appear on your screen as your 120-word sheet.</li>
</ol>

<h3><strong>Step 2: Draw Your Pattern</strong></h3>

<ol>
    <li><strong>Draw Your Pattern:</strong> Decide on a unique pattern or sequence to bury your 24-word recovery phrase. The pattern could be based on specific positions, intervals, or any method that you can easily remember.</li>
    <li><strong>Example Pattern:</strong> You might choose to insert your 24 words every fifth position starting from the third word, or you might spread them out using a custom sequence only you know.</li>
</ol>

<h3><strong>Step 3: Record Your 120-Word Sheet</strong></h3>

<ol>
    <li><strong>Write Down the Sheet:</strong> On a piece of paper, write down the entire 120-word sheet, including the buried 24-word recovery phrase according to the pattern you’ve selected.</li>
    <li><strong>Check for Accuracy:</strong> Double-check to ensure that your 24-word recovery phrase is correctly embedded in the 120-word sheet.</li>
</ol>

<h3><strong>Step 4: Secure Your Sheet</strong></h3>

<ol>
    <li><strong>Take a Picture:</strong> Once you’ve recorded your 120-word sheet, take a clear picture of it. This will serve as a backup that you can store securely or share with family members.</li>
    <li><strong>Store Safely:</strong> While the security lies in the pattern you’ve chosen, store the picture or physical copy of your 120-word sheet in a location that you can easily access but is also safe from unauthorized access.</li>
</ol>

<h3><strong>Step 5: Recall Your Recovery Phrase</strong></h3>

<ol>
    <li><strong>Use Your Pattern:</strong> When you need to recall your recovery phrase, simply apply the pattern you selected to extract your 24 words from the 120-word sheet.</li>
    <li><strong>Verify and Access:</strong> After reconstructing your 24-word recovery phrase, verify it for accuracy before using it to access your cryptocurrency wallet.</li>
</ol>

<h2><strong>Tips for Security</strong></h2>

<ul>
    <li><strong>Memorize Your Pattern:</strong> The security of your recovery phrase lies in the pattern you’ve chosen. Ensure that this pattern is something you can easily recall.</li>
    <li><strong>Don’t Share Your Pattern:</strong> Keep your pattern secret. Even if someone gains access to your 120-word sheet, they won’t be able to reconstruct your recovery phrase without knowing the exact pattern.</li>
</ul>

<h2><strong>Conclusion</strong></h2>

<p>With Hidephrase.com, you have a powerful tool to securely protect your recovery phrase without the need for expensive hardware or complex security measures. By embedding your 24-word recovery phrase within a 120-word sheet, you are leveraging the power of permutations and ensuring that your cryptocurrency assets remain safe.</p>
        </div>
      </main>
    </div>
  );
}

export default GettingStarted;