import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import GettingStarted from './GettingStarted';
import Donation from './Donation';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Store Your Recovery Phrase Smart</h1>
          <nav className="nav-links">
            <Link to="/" className="nav-button">Home</Link>
            <Link to="/getting-started" className="nav-button">Getting Started</Link>
            <Link to="/donation" className="nav-button">Donation</Link>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/getting-started" element={<GettingStarted />} />
          <Route path="/donation" element={<Donation />} />
        </Routes>
        <footer className="App-footer">
          <div className="footer-info">
            <p>&copy; 2024 Hide Phrase. All rights reserved.</p>
            <p>Contact me: <a href="mailto:yuliangjin888@gmail.com">yuliangjin888@gmail.com</a></p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;